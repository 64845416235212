.saveStateButton {
  width: 6rem;
  color: #1aacac !important;
  background-color: transparent;
  border-radius: 5px;
  text-transform: unset !important;
  height: 2.5rem;
}

.saveStateButton:disabled {
  background-color: transparent;
  color: #a7a7a7 !important;
}
